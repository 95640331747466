import React, { useEffect, useMemo, useState } from 'react';
import { withErrorBoundary as withSentryErrorBoundary } from '@sentry/react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CancelTransaction } from '../../components/modal/CancelTransaction';

import { useSelector } from 'react-redux';

import { parseDate } from 'spa/features/transaction/utils/parsers';
import { DEFAULT_ESCROW_PAGE } from 'spa/constants/HeaderConstants';
import HeaderV3 from 'spa/containers/HeaderV3';

import { getWindow } from '../../../../../utils/globals';

import {
  useGetLatestTransactionStatusHistoryQuery,
  useGetTransactionDisbursementMethodsQuery,
  useGetTransactionExtraDetailsQuery,
  useGetTransactionStepsQuery,
  useGetTransactionHistoryQuery,
  useGetTransactionPaymentMethodsQuery,
  useGetTransactionQuery,
  useSearchTransactionByIdQuery,
} from '../../transactionApi';
import { useGetStripePaymentLinkQuery } from '../../stripeApi';
import { useIsAuthedQuery } from '../../../auth/authApi';

import {
  getDNHPaymentSchedule,
  getMilestoneDetails,
  processTransactionJson,
} from '../../utils/transactionResponse';
import TransactionStepper from '../../components/TransactionStepper';
import TransactionFaqs from '../../components/faqs/TransactionFaqs';
import TransactionMiniStepper from '../../components/mini-stepper/TransactionMiniStepper';
import mapping from '../../components/faqs/mapping';
import { getDnhInstruction, getInstruction } from '../../components/instruction/InstructionMapping';
import TransactionInstruction from '../../components/instruction/TransactionInstruction';
import TransactionInformation from '../../components/information/TransactionInformation';

import TransactionHistory from '../../components/history/TransactionHistory';
import TransactionHistoryList from '../../components/history/TransactionHistoryList';
import TransactionHistoryModal from '../../components/history/TransactionHistoryModal';
import { EscrowPaper } from '../../../ui/EscrowPaper';
import ItemRejectionBanner from '../../components/banners/ItemRejectionBanner';
import UpsellBanner from '../../components/banners/UpsellBanners';
import TransactionItemDetails from '../../components/item-details/TransactionItemDetails';
import TransactionPaymentProcessingDetails from '../../components/item-details/TransactionPaymentProcessingDetails';
import ShippingInformation from '../../components/ShippingInformation';
import { isOtherPartyConfidential } from '../../utils/confidentiality';
import { TransactionDetailsErrorScreen } from './TransactionDetailsErrorScreen';
import TransactionOutstandingActions from 'spa/features/transaction/components/outstanding-actions/TransactionOutstandingActions';
import TransactionTags from 'spa/containers/Transactions/TransactionTags';
import { transformSingleTransaction } from 'spa/selectors/TransactionSelectors';
import API from '../../../../../api';

const window = getWindow();

const AUTH_POLLING_INTERVAL = 30000; // in milliseconds

const CancelTransactionButton = ({ setOpenCancelModal }) => (
  <Box
    sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
    onClick={() => setOpenCancelModal(true)}
  >
    <button className="kyc-cancelTransactionButton">Cancel transaction</button>
  </Box>
);

const TransactionDetailsPage = ({ transactionId }) => {
  const { error } = useIsAuthedQuery(undefined, { pollingInterval: AUTH_POLLING_INTERVAL });

  useEffect(() => {
    if (error) {
      const params = new URLSearchParams();
      params.set('loginLocation', window.location.pathname + window.location.search);
      window.location.href = `/login-page?${params}`;
    }
  }, [error]);

  const theme = useTheme();
  const isMobileViewport = useMediaQuery(theme.breakpoints.down('md'));

  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [errorPageIsLoading, setErrorPageIsLoading] = useState(false);

  // Scaffolding to fetch transaction data

  // TODO: replace search-transaction-by-id with get-transaction when actions data are included
  const {
    data: transData,
    error: transError,
    isFetching: transIsFetching,
  } = useGetTransactionQuery(transactionId);

  const {
    data: transactionActionData,
    error: transactionActionError,
  } = useSearchTransactionByIdQuery(transactionId);

  const {
    data: extraData,
    error: extraError,
    isFetching: extraIsFetching,
  } = useGetTransactionExtraDetailsQuery(transactionId);

  const {
    data: stepsData,
    error: stepsError,
    isFetching: stepsIsFetching,
  } = useGetTransactionStepsQuery(transactionId);

  const {
    data: history,
    error: historyError,
    isFetching: historyIsFetching,
  } = useGetTransactionHistoryQuery(transactionId);

  // This will be used when we build the ui
  /* eslint-disable no-unused-vars */
  const transactionDetails = useMemo(() => {
    if (transIsFetching || extraIsFetching) {
      return undefined;
    }

    if (transError || extraError) {
      if (
        transError &&
        transError.status &&
        transError.status === 403 &&
        transError.data &&
        transError.data.error &&
        transError.data.error === 'Not a party in the transaction'
      ) {
        setErrorPageIsLoading(true);
        window.location = `${window.config.www_base_url}/404`;
      }
      return undefined;
    }

    const processedTransaction = processTransactionJson(transData, extraData);

    return processedTransaction;
  }, [transIsFetching, extraIsFetching, transError, extraError, transData, extraData]);

  const [skipStripe, setSkipStripe] = useState(true);

  const {
    data: latestStatusData,
    error: latestStatusError,
    isFetching: latestStatusIsFetching,
  } = useGetLatestTransactionStatusHistoryQuery(transactionId);
  let afterStripe = false;
  if (latestStatusData) {
    const afterStripeDate = new Date('19 September 2024 14:00:00 UTC+8');
    const latestStatusDate = new Date(latestStatusData.change_date);
    // naively read PST context w/o daylight savings
    latestStatusDate.setHours(latestStatusDate.getHours() + 8);
    afterStripe = latestStatusDate >= afterStripeDate;
  }

  const transactionHistory = useMemo(() => {
    if (historyIsFetching) {
      return undefined;
    }

    if (historyError) {
      return undefined;
    }

    return history
      .map((item) => ({
        dateAdded: parseDate(item.date_added),
        notes: item.notes,
      }))
      .reverse(); // api returns in chronological order
  }, [historyIsFetching, historyError, history]);

  const milestoneDetails = useMemo(() => {
    if (transIsFetching) {
      return undefined;
    }

    if (transError) {
      return undefined;
    }

    const processedMilestoneDetails = getMilestoneDetails(transData);

    return processedMilestoneDetails;
  }, [transData, transError, transIsFetching]);

  const dnhPaymentSchedule = useMemo(() => {
    if (transIsFetching) {
      return undefined;
    }

    if (transError) {
      return undefined;
    }

    const processedDNHPaymentSchedule = getDNHPaymentSchedule(transData);

    return processedDNHPaymentSchedule;
  }, [transData, transError, transIsFetching]);

  const transactionSteps = useMemo(() => {
    if (stepsData) {
      return stepsData.map((step) => ({
        title: step.title,
        inProgress: step.in_progress,
        completed: step.complete,
      }));
    }
    return undefined;
  }, [stepsData]);
  const customer = useSelector((state) => state.customer);
  const currentUserRole = useMemo(() => {
    if (!transData || !transData.parties || !customer) {
      return undefined;
    }
    const matchingParty = transData.parties.find((party) => party.customer === customer.custEmail);
    if (matchingParty === undefined) {
      return undefined;
    }
    return matchingParty.role;
  }, [transData, customer]);

  const {
    data: paymentMethods,
    error: paymentMethodsError,
    isFetching: paymentMethodsIsFetching,
  } = useGetTransactionPaymentMethodsQuery(transactionId, { skip: currentUserRole !== 'buyer' });

  if (
    skipStripe === true &&
    paymentMethods &&
    paymentMethods.selected_payment_method === 'credit_card' &&
    afterStripe &&
    transactionDetails &&
    transactionDetails.transactionStatus === 20
  ) {
    setSkipStripe(false);
  }

  const {
    data: stripeData,
    error: stripeError,
    isFetching: stripeIsFetching,
  } = useGetStripePaymentLinkQuery(transactionId, {
    skip: skipStripe,
  });

  const {
    data: disbursementMethods,
    error: disbursementMethodsError,
    isFetching: disbursementMethodsIsFetching,
  } = useGetTransactionDisbursementMethodsQuery(transactionId, {
    skip: !(currentUserRole === 'seller' || currentUserRole === 'broker'),
  });

  const kycRequired = useMemo(() => {
    if (!transactionDetails || !customer) {
      return false;
    }

    if (customer) {
      if (customer.verificationStatus) {
        // Ideally we should only need to check for the presence of the blocking
        // condition. However, currently(20/07/2023) the backend doesn't correctly
        // update for all cases e.g. passing electronic kyc doesn't remove the t2 kyc
        // blocking condition.
        const notIndividualPendingOrApproved =
          customer.verificationStatus.individual &&
          !(
            customer.verificationStatus.individual.isApproved ||
            customer.verificationStatus.individual.isInProgress
          );

        const notCompanyPendingOrApproved =
          customer.verificationStatus.company &&
          !(
            customer.verificationStatus.company.isApproved ||
            customer.verificationStatus.company.isInProgress
          );

        if (transactionDetails.tier1KycRequired) {
          return true;
        }

        if (transactionDetails.tier2KycRequired && notIndividualPendingOrApproved) {
          return true;
        }
        if (transactionDetails.tier3KycRequired && notCompanyPendingOrApproved) {
          return true;
        }
      }

      if (
        customer.requireExtendedKyc &&
        !customer.extendedKycVerified &&
        (!customer.extendedKycSubmitted || customer.extendedKycRejected)
      ) {
        return true;
      }
    }
    return false;
  }, [customer, transactionDetails]);

  const otherPartyIsConfidential = useMemo(() => {
    if (transactionDetails && currentUserRole) {
      return isOtherPartyConfidential(currentUserRole, transactionDetails.partyHiddenFrom);
    }

    return true;
  }, [currentUserRole, transactionDetails]);

  const transactionInstruction = useMemo(() => {
    if (!transactionDetails || !currentUserRole) {
      return undefined;
    }
    let instruction;
    if (transactionDetails.transactionTypeCode === 'domain_name_holding') {
      instruction = getDnhInstruction(
        transactionDetails.transactionStatus,
        currentUserRole,
        dnhPaymentSchedule,
        transactionDetails.currency,
        transactionDetails.fees[transactionDetails.parties.buyer.email],
        transactionDetails.cancellationStatus
      );
    } else {
      instruction = getInstruction(
        transactionDetails.transactionTypeCode,
        transactionDetails.transactionStatus,
        transactionDetails.shippingType !== undefined,
        transactionDetails.inArbitration,
        currentUserRole,
        kycRequired,
        transactionDetails.additionalDocumentsRequested,
        transactionDetails.disbursementMethodRequired,
        paymentMethods ? paymentMethods.selected_payment_method : undefined,
        transactionDetails.cancellationStatus,
        transactionDetails.transactionItems,
        skipStripe,
        transactionDetails.partnerId,
        otherPartyIsConfidential,
      );
    }
    return instruction;
  }, [
    currentUserRole,
    transactionDetails,
    dnhPaymentSchedule,
    kycRequired,
    paymentMethods,
    skipStripe,
    otherPartyIsConfidential,
  ]);

  const isRejectedStatus = transactionDetails
    ? transactionDetails.transactionStatus >= 45 && transactionDetails.transactionStatus <= 60
    : false;

  const multipleItems = transactionDetails
    ? (transactionDetails.transactionItems.length > 0 &&
        transactionDetails.transactionItems[0].quantity > 1) ||
      transactionDetails.transactionItems.length > 1
    : false;

  const showContactInformation = useMemo(
    () =>
      transactionDetails
        ? (transactionDetails.transactionStatus === 25 && currentUserRole === 'seller') ||
          (transactionDetails.transactionStatus === 30 && currentUserRole === 'seller') ||
          (transactionDetails.transactionStatus === 45 && currentUserRole === 'buyer') ||
          (transactionDetails.transactionStatus === 50 && currentUserRole === 'buyer')
        : false,
    [currentUserRole, transactionDetails]
  );

  const flippaHideBrokerContactInformation = useMemo(
    () =>
      transactionDetails ?
      transactionDetails.partnerId === window.config.flippa_partner_id &&
      transactionDetails.transactionTypeCode === 'domain_name' &&
      otherPartyIsConfidential &&
      transactionDetails.transactionStatus >= 25 &&
      transactionDetails.transactionStatus <= 50
      : false,
    [transactionDetails, otherPartyIsConfidential]
  )

  const otherPartyRole = useMemo(() => {
    if (currentUserRole === 'buyer') {
      return 'seller';
    } else if (currentUserRole === 'seller') {
      return 'buyer';
    }
  }, [currentUserRole]);

  const otherPartyRoleForContract = useMemo(() => {
    if (otherPartyIsConfidential) {
      return 'broker';
    }
    return otherPartyRole;
  }, [otherPartyRole, otherPartyIsConfidential]);

  const otherPartyRoleForShipping = useMemo(() => {
    if (transactionDetails && transactionDetails.parties[otherPartyRole]) {
      const party = transactionDetails.parties[otherPartyRole];
      if (party.email && party.address && party.phoneNumber) {
        return otherPartyRole;
      }
    }
    return otherPartyRoleForContract;
  }, [otherPartyRole, otherPartyRoleForContract, transactionDetails]);

  const showPaymentProcessing = useMemo(() => {
    if (!transactionDetails) {
      return false;
    }
    return transactionDetails.transactionStatus >= 3 && transactionDetails.transactionStatus <= 15;
  }, [transactionDetails]);

  const shippingInformation = useMemo(
    () =>
      showContactInformation &&
      !flippaHideBrokerContactInformation &&
      otherPartyRoleForShipping &&
      !transactionDetails.usesEbayAuthenticator &&
      (otherPartyRoleForShipping !== 'broker' || 'broker' in transactionDetails.parties) && (
        <ShippingInformation
          role={otherPartyRoleForShipping}
          transactionTypeCode={transactionDetails.transactionTypeCode}
          party={transactionDetails.parties[otherPartyRoleForShipping]}
          isMobileViewport={isMobileViewport}
          sx={{ marginTop: '32px' }}
          mobileSx={{ marginTop: '16px' }}
        />
      ),
    [showContactInformation, transactionDetails, isMobileViewport, otherPartyRoleForShipping]
  );

  const upsellType = useMemo(() => {
    if (!transactionDetails) {
      return undefined;
    }
    const upsellKeys = ['concierge', 'titleCollection', 'lienHolder'];
    const _upsellType = Object.keys(transactionDetails)
      .filter((key) => upsellKeys.includes(key))
      .filter((key) => transactionDetails[key] === true);
    return _upsellType.length > 0 ? _upsellType[0] : undefined;
  }, [transactionDetails]);

  const upsellPromotionType = useMemo(() => {
    if (!transactionDetails || !transactionDetails.transactionTypeCode) {
      return undefined;
    }
    const transactionType = transactionDetails.transactionTypeCode;
    if (
      transactionType === 'domain_name' &&
      transactionDetails.transactionItems.length > 0 &&
      transactionDetails.transactionItems[0].category === 'domain_name'
    ) {
      return 'domainNamePromote';
    }

    return undefined;
  }, [transactionDetails]);

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const handleClose = () => {
    setOpenCancelModal(false);
  };

  const showCancelButton = useMemo(
    () =>
      transactionDetails &&
      transactionDetails.transactionStatus > 0 &&
      transactionDetails.transactionStatus <= 15 &&
      (currentUserRole === 'broker' || !('broker' in transactionDetails.parties)),
    [transactionDetails, currentUserRole]
  );

  const shouldShowOutstandingActionsSummary = transactionActionData
    && transactionActionData.role === 'broker'
    && transactionActionData.status_code >= 15
    && transactionActionData.status_code < 25
    && transactionActionData.status_code !== 21;

  const custId = customer ? customer.custId : undefined;

  const [brokerDetailsTest, setBrokerDetailsTest] = useState();

  useEffect(() => {
    if (!shouldShowOutstandingActionsSummary || !custId) {
      return;
    }
    API.getAbTest({
      action: "get_or_create_enrollment",
      ab_test_name: "2024_broker_txn_party_actions",
      enrollment_type: "control",
      reference_type: "customer",
      reference_id: custId,
    }).catch(() =>
      // Without the test, don't display the box
       ({ enrollment_type: 'control' })
    ).then((value => {
      setBrokerDetailsTest(value);
    }));
  }, [custId, shouldShowOutstandingActionsSummary]);

  if (
    transIsFetching ||
    extraIsFetching ||
    stepsIsFetching ||
    historyIsFetching ||
    disbursementMethodsIsFetching ||
    paymentMethodsIsFetching ||
    latestStatusIsFetching ||
    (!skipStripe && stripeIsFetching) ||
    errorPageIsLoading
  ) {
    return (
      <React.Fragment>
        <HeaderV3 title="Transaction details" pageType={DEFAULT_ESCROW_PAGE} />
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <CircularProgress color="secondaryLight" aria-label="Loading Spinner" />
        </Box>
      </React.Fragment>
    );
  }

  if (
    transError ||
    extraError ||
    stepsError ||
    transactionActionError ||
    historyError ||
    paymentMethodsError ||
    latestStatusError ||
    disbursementMethodsError
  ) {
    let xRequestId = null;
    if (transError) {
      xRequestId = transError.xRequestId;
    } else if (extraError) {
      xRequestId = extraError.xRequestId;
    } else if (paymentMethodsError) {
      xRequestId = paymentMethodsError.xRequestId;
    } else if (stepsError) {
      xRequestId = stepsError.xRequestId;
    } else if (historyError) {
      xRequestId = historyError.xRequestId;
    } else if (transactionActionError) {
      xRequestId = transactionActionError.xRequestId;
    } else if (disbursementMethodsError) {
      xRequestId = disbursementMethodsError.xRequestId;
    } else if (latestStatusError) {
      xRequestId = latestStatusError.xRequestId;
    }

    return <TransactionDetailsErrorScreen xRequestId={xRequestId} />;
  }

  return (
    <React.Fragment>
      <HeaderV3 title="Transaction details" pageType={DEFAULT_ESCROW_PAGE} />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          padding: '16px 8px',
          backgroundColor: theme.palette.mono.xxlight,
          position: 'relative',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            maxWidth: 'min(1120px, 100%)',
          }}
        >
          <Grid item xs={12} md={8}>
            <Stack spacing={2}>
              {stripeError && (
                <Alert
                  variant="outlined"
                  severity="error"
                  data-testid="item-rejection-banner"
                  sx={{ bgcolor: 'background.paper' }}
                >
                  <AlertTitle> Unable to fetch the instructions for your transaction </AlertTitle>
                  Please refresh the page and try again. If the issue persists, contact
                  support@escrow.com.
                </Alert>
              )}
              {isRejectedStatus && (
                <ItemRejectionBanner
                  role={currentUserRole}
                  transactionTypeCode={transactionDetails.transactionTypeCode}
                  multipleItems={multipleItems}
                />
              )}
              {transactionDetails && !isRejectedStatus && upsellType && (
                <UpsellBanner upsellType={upsellType} />
              )}
              {!upsellType && upsellPromotionType && transactionDetails.transactionStatus < 25 && (
                <UpsellBanner upsellType={upsellPromotionType} />
              )}
              <EscrowPaper sx={{ fontSize: "14px" }}>
                {transactionDetails && (
                  <TransactionInformation
                    transactionTitle={transactionDetails.title}
                    transactionId={transactionDetails.id}
                    transactionTypeCode={transactionDetails.transactionTypeCode}
                    inspectionDays={transactionDetails.inspectionDays}
                    parties={transactionDetails.parties}
                    multipleItems={multipleItems}
                    userRole={currentUserRole}
                    partyHiddenFrom={transactionDetails.partyHiddenFrom}
                    sx={{ marginBottom: '32px' }}
                  />
                )}
                {transactionActionData && (
                  <TransactionTags
                    transaction={transformSingleTransaction(transactionActionData)}
                    includePartyActions={false}
                    style={{
                      justifyContent: "left",
                      paddingRight: "0",
                      marginTop: "-1em",
                      marginBottom: "2em",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  />
                )}
                {!isMobileViewport && transactionSteps && (
                  <TransactionStepper steps={transactionSteps} variant={'detailsPage'} />
                )}
                {isMobileViewport && transactionSteps && transactionSteps.length > 0 && (
                  <Box onClick={() => setHistoryModalOpen(true)} sx={{ marginBottom: '24px' }}>
                    <TransactionMiniStepper steps={transactionSteps} />
                  </Box>
                )}
                {isMobileViewport && history && (
                  <React.Fragment>
                    <TransactionHistoryList
                      history={transactionHistory}
                      truncated
                      seeMoreHandler={() => {
                        setHistoryModalOpen(true);
                      }}
                    />
                  </React.Fragment>
                )}
                {transactionInstruction && !stripeError && (
                  <TransactionInstruction
                    transactionId={transactionId}
                    instruction={transactionInstruction}
                    inspectionDays={transactionDetails.inspectionDays}
                    sx={{ marginTop: '32px' }}
                    userRole={currentUserRole}
                    disbursementMethods={disbursementMethods}
                    shippingInformation={isMobileViewport ? shippingInformation : null}
                    paymentLink={!skipStripe && stripeData ? stripeData.payment_link_url : ''}
                    listingReference={transactionDetails.listingReference}
                  />
                )}
                {!isMobileViewport && shippingInformation}
              </EscrowPaper>
              {shouldShowOutstandingActionsSummary && brokerDetailsTest &&
                brokerDetailsTest.enrollment_type === 'variation' &&
                (<EscrowPaper>
                  <TransactionOutstandingActions
                    transaction={transactionActionData}
                    isMobileViewport={isMobileViewport}
                  />
                </EscrowPaper>)
              }
              {transactionDetails && (
                <TransactionItemDetails
                  transaction={transactionDetails}
                  userRole={currentUserRole}
                />
              )}
              {transactionDetails && showPaymentProcessing && currentUserRole === 'buyer' && (
                <TransactionPaymentProcessingDetails
                  transaction={transactionDetails}
                  paymentMethods={paymentMethods}
                />
              )}
              {openCancelModal && (
                <CancelTransaction
                  open={openCancelModal}
                  transactionId={transactionId}
                  handleClose={handleClose}
                  handleBack={handleClose}
                  isMobileViewport={isMobileViewport}
                />
              )}
              {showCancelButton && !isMobileViewport && (
                <CancelTransactionButton setOpenCancelModal={setOpenCancelModal} />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={2}>
              {history && !isMobileViewport && <TransactionHistory history={transactionHistory} />}
              {transactionDetails && (
                <TransactionFaqs
                  mapping={mapping[currentUserRole]}
                  transactionStatus={transactionDetails.transactionStatus}
                  transactionType={transactionDetails.transactionTypeCode}
                />
              )}
            </Stack>
          </Grid>
          {showCancelButton && isMobileViewport && (
            <CancelTransactionButton setOpenCancelModal={setOpenCancelModal} />
          )}
        </Grid>
        {transactionHistory && isMobileViewport && (
          <TransactionHistoryModal
            history={transactionHistory}
            transactionSteps={transactionSteps}
            handleClose={() => setHistoryModalOpen(false)}
            isOpen={historyModalOpen}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default withSentryErrorBoundary(TransactionDetailsPage, {
  fallback: <TransactionDetailsErrorScreen />,
});
